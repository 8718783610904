<template>
    <div>
        <div class="statisticsModule">
            <van-sticky z-index="1" class="statisticsModuleHeader">
                <div class="statisticsModuleTitle">
                    <div class="statisticsModuleLeftReturn">
                        <van-image class="statisticsModuleLeftReturnImg" fit="contain" :src="require('../../../assets/images/left-white.png')" @click="leftReturn"/>
                    </div>
                    <div class="statisticsModuleTitleName">
                        <span>工资审批</span>
                    </div>
                </div>
                <van-tabs :border="false" title-style="z-index:998;" @click="onTabClick"
                        sticky
                        title-active-color="white"
                        title-inactive-color="#FFE1C7"
                        color="white"
                        line-width="40px" line-height="4px"
                        background="linear-gradient(to right, #ffc274, #ff5d3b)" v-model="active">
                    <van-tab title="工资条"></van-tab>
                    <van-tab title="补扣项"></van-tab>
                    <van-tab title="审批记录"></van-tab>
                    <!-- <van-tab title="业绩"></van-tab>
                    <van-tab title="佣金"></van-tab> -->
                </van-tabs>
            </van-sticky>
            <div class="statisticsModuleBody">
                <!-- 工资条-->
                <div v-if="active==0">
                    <payslip ref="payslip"></payslip>
                </div>
                <!-- 补扣项-->
                <div v-if="active==1">
                    <addition ref="addition"></addition>
                </div>
                <!-- 业绩-->
                <!-- <div v-if="active==2">
                    <basicPersonnelModule ref="basicPersonnelModule"></basicPersonnelModule>
                </div> -->
                <!-- 佣金-->
                <!-- <div v-if="active==3">
                    <financialModule ref="financialModule"></financialModule>
                </div> -->
                <!-- 审批记录 -->
                <div v-if="active==2">
                    <approvalRecord ref="approvalRecord"></approvalRecord>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        Button, Search, DropdownMenu, DropdownItem, Divider, List, NavBar, Picker,PullRefresh,Popup,Sticky,Tabs,Tab,Image as VanImage,
    } from 'vant';
    import {decorationReviewListInit, getStaffFuntionModelList, queryBaseData,portAuditList} from "../../../getData/getData";
    import {checkAuthList, getStaffId, responseUtil,globaluserId,checkAndroid, checkIOS,openInWebview} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import payslip from './payslip'
    import addition from './addition'
    import approvalRecord from './approvalRecord'
    //调用android关闭页面方法*******begin*********
    function backToAndroid(){
      window.himi.closeWindow();
    }
    //调用android关闭页面方法*******end***********
export default {
    name: 'salaryApplicationDetail',
    components: {
        [Button.name]: Button,
        [Search.name]: Search,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Divider.name]: Divider,
        [NavBar.name]: NavBar,
        [List.name]: List,
        rxNavBar,
        [Picker.name]: Picker,
        [Popup.name]: Popup,
        [PullRefresh.name]:PullRefresh,
        [Sticky.name]: Sticky,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [VanImage.name]: VanImage,
        payslip,
        addition,
        approvalRecord,
    },
    data(){
        return{
            // navTitle:'工资审批',
            // offsetTop: '49.9',
            // followUpHistoryList:[],
            active:0,
        }
    },
    mounted() {
        // this.getStaffFuntionModelList()
    },
    created() {
        this.active=0
        //详情
        // this.initData()

        // this.decorationConstructionDetails()
        // this.initAfterrentHisotry()
    },
    methods:{
        leftReturn() {
            // if(openInWebview()){
            //     //APP传值*******begin*********
            //     if(checkAndroid()){
            //         console.log("----checkAndroid()-=true")
            //         backToAndroid();
            //     }else if(checkIOS()){
            //         console.log("----checkAndroid()-=true")
            //         window.webkit.messageHandlers.closeWindow.postMessage({});
            //         // console.log("对ios传参，调用IOS关闭页面方法,zheshi");
            //     }
            // //APP传值*******end*********
            // }else{
                this.$router.go(-1)
            // }
        },

        // 滚动时获取nav高度并赋值
        scroll() {
            if (this.$refs.navBar) {
                this.offsetTop = this.$refs.navBar.clientHeight
            }
        },

        onTabClick(name,title){
          if(name==0){
            this.$nextTick(()=>{
              this.$refs['payslip'].queryPayrollApprovalDetailListCount();
            })
          }else if(name==1){
            this.$nextTick(()=>{
              // this.$refs.addition.queryAddition()
              this.$refs.addition.queryAdditionCount()
            })
          }
        }




    }
}
</script>

<style scoped>
    .statisticsModuleHeader {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999;
        background: linear-gradient(to right, #ffc274, #ff5d3b);
    }
    .statisticsModuleBody {
        margin-top: 2.5rem;
    }
    .statisticsModuleTitle {
        width: 100%;
        height: 1.33333rem;
    }
    .statisticsModuleLeftReturn {
        width: 2rem;
        font-size: 0.37333rem;
        height: 100%;
        float: left;
    }
    .statisticsModuleLeftReturnImg {
        width: 58%;
        height: 60%;
        margin-top: 10px;
    }
    .statisticsModuleTitleName {
        color: white;
        font-size: 18px;
        width: 6rem;
        height: 100%;
        float: left;
        line-height: 1.33333rem;
        text-align: center;
    }
</style>
