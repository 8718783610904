<template>
    <div>
        <div class="approve">
                    <div class="time_line">
                        <div class="potential">
                            <p class="subtitle">基本信息</p>
                            <div class="panel">
                                <div class="t_table">
                                    <div class="t_body" >
                                        <van-row class="itemFontStyle">
                                            <van-col span="1" class="t_td"></van-col>
                                            <van-col span="8" class="t_td">发起审批时间：</van-col>
                                            <van-col span="1" class="t_td_1"></van-col>
                                            <van-col span="10" class="t_td">{{ followUpHistoryList.addTime }}</van-col>
                                            <van-col span="1" class="t_td"></van-col>
                                        </van-row>
                                        <van-row>
                                            <van-col span="1" class="t_td"></van-col>
                                            <van-col span="8" class="t_td">审批结果：</van-col>
                                            <van-col span="1" class="t_td_1"></van-col>
                                            <van-col span="10" class="t_td">{{ followUpHistoryList.salaryStatusName }}</van-col>
                                            <van-col span="1" class="t_td"></van-col>
                                        </van-row>
                                        <van-row class="itemFontStyle" v-if="followUpHistoryList.backStaffName">
                                            <van-col span="1" class="t_td"></van-col>
                                            <van-col span="8" class="t_td">撤回人：</van-col>
                                            <van-col span="1" class="t_td_1"></van-col>
                                            <van-col span="10" class="t_td">{{ followUpHistoryList.backStaffName }}</van-col>
                                            <van-col span="1" class="t_td"></van-col>
                                        </van-row>
                                        <van-row class="itemFontStyle" v-if="followUpHistoryList.backTime">
                                            <van-col span="1" class="t_td"></van-col>
                                            <van-col span="8" class="t_td">撤回时间：</van-col>
                                            <van-col span="1" class="t_td_1"></van-col>
                                            <van-col span="10" class="t_td">{{ followUpHistoryList.backTime }}</van-col>
                                            <van-col span="1" class="t_td"></van-col>
                                        </van-row>
                                    </div>
                                </div>
                            </div> 
                            <p class="subtitle">审批流</p>
                            <div class="item" v-for="(item,index) in followUpHistoryList.data" :key="index">
                            <div class="left">
                                <div :class="{line:true ,line_top:true,first_top:index==0}"></div>
                                <div class="point"></div>
                                <div :class="{line:true, line_bottom :true,lastLine:index==followUpHistoryList.length-1}"></div>
                            </div>
                            <div class="right">
                                <div class="genjinOne">
                                    <div class="followInfo">
                                        <div class="followInfoDiv">
        <!--                                    <div class="followType">{{item.dictionaryTitle}}</div>-->
                                            <p class="followType">{{item.dictionaryTitle}}</p>
                                            <div class="name">{{item.type==1?'评论人':'审批人'}}：{{item.staffName}}</div>
                                            <div class="time">{{item.type==1?'评论时间':'审批时间'}}：{{item.type==1?item.addTime:item.approvalTime}}</div>
                                            <div class="time" v-if="item.type!=1">审批状态：{{item.approvalStatusName}}</div>
                                        </div>
                                        <div class="followDetail">
                                        <span> 审批意见：{{item.approvalOpinion}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>


                    </div>
                </div>
    </div>
</template>

<script>
import { Field,Button, Row, Col,Popup } from 'vant'
import {findApprovalHistoryByPayrollManageId} from "../../../getData/getData";
import {getStaffId, globaluserId,responseUtil} from "../../../libs/rongxunUtil";
export default {
    name: 'approvalRecord',
    components: {
        [Button.name]: Button,
        [Field.name]:Field,
        [Row.name]: Row,
        [Col.name]: Col,
        [Popup.name]: Popup,
    },
    data(){
        return{
            followUpHistoryList:{
                addTime:'',
                salaryStatusName:'',
                data:[
                    {
                        addTime:'',
                        salaryStatusName:'',
                        dictionaryTitle:'',
                        staffName:'',
                        approvalTime:'',
                        approvalStatusName:'',
                        approvalOpinion:'',
                    }
                ]
            },
        }
    },
    mounted() {
        //审批记录
        this.followUpHistory()

        // this.decorationConstructionDetails()
        // this.initAfterrentHisotry()
    },
    methods:{
        // 跟进记录
        followUpHistory: function (id) {
            var that = this
            let initData = {}
            initData.user_id = globaluserId()
            initData.payrollManage_id = that.$route.query.payrollmanage_id
            findApprovalHistoryByPayrollManageId(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.followUpHistoryList = response.data.data
                })
            })
        },
    }
}
</script>


<style lang="less" scoped>
    @redius: 8px;
    @spanColor: rgba(102, 102, 102, 1);
    .approve {
        /*margin-top: 12px;*/
        font-size: 14px;
        float: left;
        margin-bottom: 20px;
        .time_line {
            .item {
                display: flex;
                align-items: stretch;
                justify-content: space-between;

                .left {
                    /*margin-top: -35px;*/
                    width: 20px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    /*justify-content: center;*/
                    align-items: center;
                    margin-left: 15px;
                    /*margin-right: 5px;*/

                    .line {
                        width: 1px;
                        background-color:#3191FF;
                        /*position: absolute;*/
                        &.line_top {
                            top: 0px;
                            /*<!--height: ceil(50%-8px);-->*/
                            height: 35px;
                            &.first_top {
                                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), #3191FF );
                                /* Safari 5.1 - 6.0 */
                                background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), #3191FF );
                                /* Opera 11.1 - 12.0 */
                                background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1),#3191FF );
                                /* Firefox 3.6 - 15 */
                                background: linear-gradient(to bottom, rgba(255, 255, 255, 1), #3191FF );
                            }
                        }

                        &.line_bottom {
                            bottom: 0px;
                            height: ceil(100%-20px);
                            /*height: 100%;*/
                        }
                        &.lastLine{
                            background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, 1), #3191FF );
                            /* Safari 5.1 - 6.0 */
                            background: -o-linear-gradient(top, rgba(255, 255, 255, 1), #3191FF );
                            /* Opera 11.1 - 12.0 */
                            background: -moz-linear-gradient(top, rgba(255, 255, 255, 1),#3191FF );
                            /* Firefox 3.6 - 15 */
                            background: linear-gradient(to top, rgba(255, 255, 255, 1), #3191FF );
                            height: 20%;
                        }
                    }

                    .point {
                        position: relative;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        border: 1px solid #3191FF;
                        /*transform: translateY(-50%);*/
                        flex: 0 0 auto;
                        z-index: 99;
                        background-color: rgb(255, 255, 255);

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background-color: #3191FF ;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                        }
                    }
                }

                .right {
                    width: 100%;
                    /*padding-bottom: 15px;*/
                    /*margin-bottom: 15px;*/
                    margin-top: 15px;
                    .followType{
                        margin: 10px 0 0;
                    }
                    // .approve_panel {
                    //     margin: 0 15px;
                    //     border-radius: @redius;
                    //     background-color: rgb(255, 255, 255);
                    //     padding: 10px;

                    //     p {
                    //         padding-top: 10px;
                    //         font-weight: bolder;

                    //         &:last-of-type {
                    //             padding-bottom: 10px;
                    //         }

                    //         span {
                    //             font-weight: normal;
                    //             color: rgb(82, 82, 82);
                    //         }
                    //     }
                    // }
                }
            }

        }
    }
</style>


<style lang="less" scoped>
    .potential {
        width: 375px;
        background-color: rgb(250, 250, 250);
        padding: 0px 15px 10px;
        box-sizing: border-box;
        overflow: hidden;
        color: #252525;
        .subtitle {
            padding: 5px 0px;
            color: rgb(244, 99, 76);
            margin-top: 20px;
            margin-bottom: 1px;
            font-weight: 700;
            font-size: 13px;
            .panel {
                width: 345px;
                background-color: rgb(255, 255, 255);
                box-sizing: border-box;
                padding: 10px;
                border-radius: 8px;
                position: relative;
                font-size: 14px;
                text-align: left;
                overflow: hidden;
                // 循环表单
                .t_table {
                    .t_td {
                        text-align: left;
                        height: 40px;
                        line-height: 40px;
                        font-size: 13px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .t_td_1 {
                        text-align: left;
                        height: 40px;
                        line-height: 40px;
                        font-size: 13px;
                        padding-left: 10px;
                    }
                }
            }
        }
    }

</style>