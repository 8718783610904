<template>
    <div>
        <div class="types">
            <div class="typeDiv">
                <div class="typef" @click="popupDuty" id="downList">职务</div>
                <div :class="isDutyShow?'small-icon-rotate':'small-icon'"></div>                
            </div>
            <div class="content-divide-sa">|</div>
            <div class="typeDiv">
                <div class="order" @click="showStaff" id="downOrderList">员工</div>
                <div :class="isStaffShow?'small-icon-rotate':'small-icon'"></div>                
            </div>
            <div class="content-divide-ac">|</div>
            <div class="typeDiv">
                <div class="sif" @click="showIf" id="downIfList">条件筛选</div>
                <div :class="isIfShow?'small-icon-rotate':'small-icon'"></div>                
            </div>

        </div> 

        <!-- 职务搜索 -->
        <van-popup v-model="isDutyShow" position="bottom">
            <div class="searchDiv">
                <van-search
                placeholder="请输入"
                v-model="dutyName"
                size="large"
                @input="quaryDutyList()"
                />
            </div>
            <van-picker
                show-toolbar
                value-key="dutyName"
                :columns="dutyArr"
                @cancel="cancelDutyOption"
                cancel-button-text="重置"
                @confirm="selectDutyOption"
            />
        </van-popup>

        <!-- 员工搜索 -->
        <van-popup v-model="isStaffShow" position="bottom">
            <!--搜索栏-->
            <div class="searchDiv">
                <van-search
                placeholder="请输入"
                v-model="staffName"
                size="large"
                @input="quaryStaffList()"
                />
            </div>
            <van-picker
                show-toolbar
                value-key="staffName"
                :columns="staffArr"
                @cancel="cancelStaffOption"
                cancel-button-text="重置"
                @confirm="selectStaffOption"
            />
        </van-popup>

        <!-- 条件筛选 -->
        <van-popup class="screen" v-model="maskShow" position="right">
            <div style="height: 70%;overflow: scroll">
                <div class="screety">部门类型</div>
                <div class="sty">
                    <span class="stye"  @click="changenos() " :class="{'bgcolor':-1==spanIndex}">不限</span>
                    <span class="stye" v-for="( item,index) in departmentTypeList" :key="index" @click="changeSpan(index)"
                        :class="{'bgcolor':index==spanIndex}">{{ item.dictionaryTitle}}</span>
                </div>
                <div class="screety">员工状态</div>
                <div class="sty">
                    <span class="stye" @click="pricechangenos()" :class="{'bgcolor':-1==pricecSIdex}">不限</span>
                    <span class="styeprice" v-for="(item,index) in staffStatusList" :key="index"
                        @click="pricechangeSpan(index)" :class="{'bgcolor':index==pricecSIdex}">{{item.dictionaryTitle}}</span>
                </div>
                <div class="scrsty" style="width: 94%;position: absolute;bottom: 4%;z-index: 100">
                    <div style="display: flex;align-items: center;white-space: nowrap">
                        <div @click="cleanSpan()"><img class="clean-icon" src="../../../assets/images/4.png"></div>
                        <div class="clean_button" @click="cleanSpan()">清空</div>

                    </div>
                    <div class="screen_button" @click="screenSpan()">筛选</div>
                </div>
            </div>
        </van-popup>


        <div class="potential">
            <!-- 部门检索 -->
            <div class="module-small" @click="showProject">
                <span class="project-name">{{
                    departmentName != undefined && departmentId != ''
                    ? departmentName
                    : '部门检索'
                }}</span>
                <img class="drop-down-image" src="../../../assets/images/drop-down.png" />
            </div>
            <!--部门检索 下拉菜单-->
            <van-popup v-model="isShowProjectMenu" position="bottom" style="max-height: 85%;">
                <div style="background-color: #f7f8fa">
                    <div class="choose-company-text">选择公司</div>
                    <van-row justify="center" style="background-color: #f7f8fa">
                        <div v-for="(item, index) in companyList" :key="index">
                            <van-col span="8" offset="4" class="company-col-style"
                            ><div
                                :class="
                                checkedIndex == index
                                    ? 'company-col-checked-style'
                                    : 'company-text-style'
                                "
                                @click="selectCompany(item, index)"
                            >
                                {{ item.departmentName ? item.departmentName : '部门检索' }}
                            </div></van-col
                            >
                        </div>
                    </van-row>
                </div>
                <van-tree-select
                    :active-id.sync="activeId"
                    :main-active-index.sync="activeIndex"
                    :items="items"
                    @click-nav="selectArea"
                    @click-item="selectDepartment"
                />
                <div class="search">
                    <van-button
                        style="width: 2rem; color: orangered;border-color: orangered"
                        plain
                        type="primary"
                        @click="resetDepartmentInfo"
                    >清空</van-button>
                    <van-button
                        @click="searchDepartmentInfo"
                        style="width: 2.8rem;margin-left: 4rem"
                        color="linear-gradient(to right, #ff6034, #ee0a24)"
                        size="normal"
                    >筛选</van-button>
                </div>
            </van-popup>





            <p class="subtitle">数据统计</p>
            <div class="panel">
                合计金额：{{ totalAmount }}
            </div>
            <p class="subtitle">补扣项详情</p>
            <!-- 数据详情 -->
            <div class="dataDetail">
                <div class="dataStaffName">
                    <div style="font-size:16px;">姓名</div>
                    <div v-for="item in additionList" :key="item.id">{{ item.staffName }}</div>
                </div>
                <div class="staffData">
                    <div class="dataDetailHeaderRow">
                        <div v-for="item,index in additionHeaderList" :key="index" class="dataDetailHeader">
                            {{ item }}
                        </div>
                    </div>
                    <div v-for="item in additionList" :key="item.id" class="dataDetailRightDataRow">
                        <div class="dataDetailRightData">{{ item.addtionType }}</div>
                        <div class="dataDetailRightData">{{ item.amount }}</div>
                        <div class="dataDetailRightData">{{ item.addtionTime }}</div>
                        <div class="dataDetailRightData" @click="itemDesClick(item)">{{item.addtionDescription?'查看补扣项备注':''}}</div>
                    </div>
                    <!-- 
                     -->
                </div>

            </div>

            <van-popup v-model="showHide" title="详细信息" class="bankInfo">
                <div class="detailInfo">
                  <span>补扣备注：</span>
                  <span>{{additionInfo.dictionaryDescription}}</span>
                </div>
            </van-popup>
        </div>        
    </div>
</template>


<script>
import { Field, Button, Row, Col, Popup, Cell, Search, Picker,TreeSelect, } from 'vant'
import {queryAuthLevelDepartment,queryBaseData,queryStaffList,queyDutySelList,querySalaryHistoryAddtion,querySalaryHistoryAddtionCount} from "../../../getData/getData";
import {getStaffId, globaluserId,responseUtil,money} from "../../../libs/rongxunUtil";
export default {
    name: 'addition',
    components: {
        [Button.name]: Button,
        [Field.name]:Field,
        [Row.name]: Row,
        [Col.name]: Col,
        [Popup.name]: Popup,
        [Cell.name]: Cell,
        [Search.name]: Search,
        [Picker.name]: Picker,
        [TreeSelect.name]: TreeSelect,
    },
    data(){
        return{
            //搜索项
            // 下拉
            isDutyShow:false,  //职务
            dutyName:'',  ///筛选输入
            dutyArr:[],  //职务列表
            duty_id:'',  //选择的职务id
            isStaffShow:false,
            staffName:'',
            staffArr:[],
            staff_id:'',  //员工id

            maskShow:false,  //条件搜索框
            isIfShow:false,
            staffStatusList:[],  //员工状态
            departmentTypeList:[],  //部门类型
            salaryStatusList:[],  //缓发状态
            addtionTypeList:[],  //补扣类型

            spanIndex:-1,
            pricecSIdex:-1,

            //部门检索
            isShowProjectMenu:false,  //部门搜索
            companyList: [],  //公司列表
            level:'',
            departmentId:'',
            departmentName:'',
            departmentAuthData: {},
            functionId: '391',
            checkedIndex: 0,
            activeId: -1,
            activeIndex: 0,
            departmentLevelType:'',
            staffStatus:'',
            items: [
                {
                    text: '浙江',
                    children: [
                        { text: '杭州', id: 1 },
                        { text: '温州', id: 2 },
                    ],
                },
                {
                    text: '辽宁',
                    children: [
                        { text: '沈阳', id: 3 },
                        { text: '大连', id: 4 },
                    ],
                },
                {
                    text: '江苏',
                    children: [
                        { text: '南京', id: 5 },
                        { text: '无锡', id: 6 },
                    ],
                },
            ],


            page:{currentPage:1,numberPage:50,},
            refreshing: false,
            loading : true,
            isShow: false,
            isEmptuyFlag:true,
            finished: false,
            flag:true,
            additionHeaderList:[
                    '补扣项类型',
                    '金额',
                    '日期',
                    '备注',
            ],
            additionList:[],
            additionInfo:{},
            showHide:false,

            totalAmount:0.00,
        }
    },
    mounted() {
        this.page={currentPage:1,numberPage:50,}
        this.additionList = []     
        // this.queryAdditionCount()
        //公司初始化
        this.queryAuthLevelDepartment()
        //职务
        this.quaryDutyList()
        //人员
        this.quaryStaffList()
        this.initDictionaryData()
    },
    methods:{
        //职务筛选
        popupDuty(){
            this.isDutyShow = true
        },
        quaryDutyList() {
            let that = this
            let data = {}
            data.searchName = that.dutyName
            //queryRentTenant  seeWithUserList
            queyDutySelList(data).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                    that.dutyArr = response.data.data.dutyList
                })
            })
        },
        cancelDutyOption(){
            this.page={currentPage:1,numberPage:50,}
            this.additionList = []
            this.duty_id = ''
            this.queryAddition()
            this.queryAdditionCount()
            this.isDutyShow = false
        },
        selectDutyOption(item) {
            this.page={currentPage:1,numberPage:50,}
            this.additionList = []
            this.duty_id = item.id
            this.queryAddition()
            this.queryAdditionCount()
            this.isDutyShow = false
        },

        //人员筛选
        showStaff(){
            this.isStaffShow = true
        },
        quaryStaffList(){
            let that = this
            let data = {}
            data.searchName = that.staffName
            //queryRentTenant  seeWithUserList
            queryStaffList(data).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                  that.staffArr=response.data.data.data
                })
            })
        },
        cancelStaffOption(){
            this.page={currentPage:1,numberPage:50,}
            this.additionList = []
            this.staff_id = ''
            this.queryAddition()
            this.queryAdditionCount()
            this.isStaffShow = false
        },
        selectStaffOption(item){
            this.page={currentPage:1,numberPage:50,}
            this.additionList = []
            this.staff_id = item.id
            this.queryAddition()
            this.queryAdditionCount()
            this.isStaffShow = false
        },


        //条件筛选
        showIf: function () {
            this.maskShow = true;
        },
        changenos: function () {
            this.spanIndex = -1;
        },
        changeSpan: function (index) {
            this.spanIndex = index
        },
        pricechangenos: function (index) {
            this.pricecSIdex = -1;
        },
        pricechangeSpan: function (index) {
            this.pricecSIdex = index;
        },

        //清空
        cleanSpan: function () {
                this.spanIndex = -1;
                this.pricecSIdex = -1;
            },
        screenSpan: function () {
            this.page={currentPage:1,numberPage:50,}
            this.additionList = []
            // 房源类型
            if( this.spanIndex!= -1) {
                this.departmentLevelType = this.departmentTypeList[this.spanIndex].id
            }else{
                this.departmentLevelType =''
            }
            // 员工状态
            if( this.pricecSIdex!= -1) {
                this.staffStatus = this.staffStatusList[this.pricecSIdex].dictionaryValue
            }else{
                this.staffStatus =''
            }
            this.queryAddition()
            this.queryAdditionCount()
            this.maskShow = false;
        },

        //显示部门检索下拉菜单
        showProject() {
            this.isShowProjectMenu = true
        },

        //补扣项详情
        queryAddition(){
            var that = this
            let initData = {}
            initData.departmentLevelType = this.departmentLevelType  //部门类型
            initData.staffStatus = this.staffStatus  //员工状态
            initData.level = this.level
            initData.department_id = this.departmentId  //部门id
            initData.departmentName = this.departmentName
            initData.duty_id = this.duty_id  //职务
            initData.staff_id = this.staff_id  //员工
            initData.user_id = getStaffId()
            initData.payrollmanage_id = that.$route.query.payrollmanage_id   //
            initData.currentPage = this.page.currentPage
            initData.numberPage = this.page.numberPage
            initData.beginTime = that.$route.query.salaryTime
            initData.endTime = that.$route.query.salaryTime
            querySalaryHistoryAddtion(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    // that.addtionData = response.data.data.data
                    // that.initAddtionParam.page.pageCount = response.data.data.pageCount
                    let count = response.data.data.pageCount
                    let list=response.data.data.data
                    if(response.data.data.data && response.data.data.data.length!=0){
                        for (let i = 0; i < list.length; i++) {
                            list[i].amount = money(list[i].amount)
                            that.additionList.push(list[i])
                        }
                    }

                    if(that.refreshing){
                        that.refreshing = false
                    }
                    // 加载状态结束
                    that.loading = false;
                    that.page.currentPage++;
                    // 数据全部加载完成
                    if (that.additionList.length==0 || that.additionList.length >= count) {
                        that.finished = true;
                    }else{
                        that.queryAddition()
                    }
                })
            })
        },

        queryAdditionCount(){
            var that = this
            let initData = {}
            initData.departmentLevelType = this.departmentLevelType  //部门类型
            initData.staffStatus = this.staffStatus  //员工状态
            initData.level = this.level
            initData.department_id = this.departmentId  //部门id
            initData.departmentName = this.departmentName
            initData.duty_id = this.duty_id  //职务
            initData.staff_id = this.staff_id  //员工
            initData.user_id = getStaffId()
            initData.payrollmanage_id = that.$route.query.payrollmanage_id   //
            initData.beginTime = that.$route.query.salaryTime
            initData.endTime = that.$route.query.salaryTime
            querySalaryHistoryAddtionCount(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.totalAmount = money(response.data.data.data[0].totalAmount)
                })
            })
        },

        itemDesClick(data){
            this.additionInfo.dictionaryDescription = data.addtionDescription
            this.showHide = true
        },










        queryAuthLevelDepartment() {
            const that = this
            let initData = {}
            initData.staff_id = getStaffId() //员工id
            initData.function_id = that.functionId //方法模块id
            queryAuthLevelDepartment(initData).then(res =>
                responseUtil.dealResponse(that, res, () => {
                    that.departmentAuthData = res.data.data
                    let allList = that.departmentAuthData.list
                    if (allList != null) {
                        that.companyList = allList.filter(item => item.level == 1)
                    }
                    let departmentId = that.departmentAuthData.roleDepartmentId
                    that.departmentId = departmentId
                    let item = that.departmentAuthData.list.find(
                        item => item.id == departmentId
                    )
                    that.departmentName = item ? item.departmentName || '' : ''
                    that.initOtherDepartment()
                    that.searchDepartmentInfo()
                })
            )
        },
        initOtherDepartment() {
            let that = this
            let allList = that.departmentAuthData.list
            let departmentId = that.departmentId
            let threeLevelDepartments = allList.filter(item => item.level == 3)
            let fiveLevelDepartments = allList.filter(item => item.level == 5)
            let levelDepartments = threeLevelDepartments
                .filter(item => {
                    let levelTop = item.levelTop || ''
                    return levelTop.indexOf(departmentId) != -1
                })
                .map(item => {
                    let result = {}
                    result.children = fiveLevelDepartments
                        .filter(child => {
                            let levelTop = child.levelTop || ''
                            return levelTop.indexOf(',' + item.id) != -1
                        })
                        .map(child => {
                            let childResult = {}
                            childResult.text = child.departmentName
                            childResult.id = child.id
                            childResult.level = child.level
                            return childResult
                        })
                    result.text = item.departmentName
                    result.value = item.id
                    result.level = item.level
                    return result
                })
            that.items = levelDepartments // 公司列表
        },
        //选择公司，联动下方区、部
        selectCompany(item, index) {
            let that = this
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(that, '权限不足')
                return
            }
            this.checkedIndex = index
            this.level = item.level
            this.departmentId = item.id
            this.departmentName = item.departmentName
            this.initOtherDepartment()
        },
        selectArea(value) {
            let item = this.items[value]
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(this, '权限不足')
                return
            }
            this.level = item.level
            this.departmentId = item.value
            this.departmentName = item.text
        },
        selectDepartment(data) {
            let roleLevel = this.departmentAuthData.roleLevel || 0
            let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
            if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
                this.isShowProjectMenu = false
                responseUtil.alertErrMsg(this, '权限不足')
                return
            }
            this.level = data.level
            this.departmentId = data.id
            this.departmentName = data.text
        },
        searchDepartmentInfo() {
            this.page={currentPage:1,numberPage:50,}
            this.additionList=[]
            this.queryAddition()
            this.queryAdditionCount()
            this.isShowProjectMenu = false
        },
        resetDepartmentInfo() {
            let departmentId = this.departmentAuthData.roleDepartmentId
            let item = this.departmentAuthData.list.find(
                item => item.id == departmentId
            )
            this.departmentName = item ? item.departmentName || '' : ''
            this.departmentId = departmentId
            this.isShowProjectMenu = false
            this.searchDepartmentInfo()
        },
        // 获取字典数据
        initDictionaryData: function() {
            var that = this
            let initData = {}
            initData.user_id = getStaffId()
            initData.dbName = ['departmentType','addTionType']
            initData.fdName = ['STAFFSTATUSMAP',"APPROVESTATUSMAP",'STAFFSALARYSTATUSMAP']
            queryBaseData(initData).then(function(response) {
                responseUtil.dealResponse(that, response, () => {
                    that.staffStatusList = response.data.data.STAFFSTATUSMAP
                    // that.approvalStatusList = response.data.data.APPROVESTATUSMAP
                    that.salaryStatusList = response.data.data.STAFFSALARYSTATUSMAP
                    that.departmentTypeList = response.data.data.departmentType
                    that.addtionTypeList = response.data.data.addTionType
                })
            })
        },

    }
}
</script>

<style lang="less" scoped>
  @redius: 8px;
  @border: 1px solid rgb(244, 244, 244);

  .part-inputpart {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
  }
  .part-inputpart-dropDownList{
    height: 300px;
    /*垂直方向滚动*/
    /*overflow-y: scroll;*/
    overflow: auto;
    width: 99.5%;
    position: absolute;
    z-index: 99;
    margin:4px 0 0 -19px;
  }
  .diamod {
    width: 8px;
    height: 8px;
    background-color: #3B98FF;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 17px 7px 0 18px;
    display: inline-block;
  }

  .t_title {
    background-color: white;
    color:#33ADFF;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    height: 20px;
  }
  .approve-opinion{
    margin-top:10px
  }
  .potential {
    width: 375px;
    background-color: rgb(250, 250, 250);
    padding: 15px 15px 0;
    box-sizing: border-box;
    overflow: hidden;
    color: #252525;


    .sub_btn {
      margin: 30px 0 70px;

      .sub_button {
        border-radius: @redius;
        color: rgb(255, 255, 255);
        border: none;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

        &.not_choose {
          background: none;
          background-color: rgba(184, 184, 184, 0.2);
        }
      }
    }

    p {
      margin: 0px;
    }

    .not_choose {
      background: none;
      background-color: rgba(184, 184, 184, 0.2);
    }

    .application_time {
      text-align: right;
      width: 345px;
      font-size: 12px;
      color: rgb(136, 136, 136);
      padding: 5px 0 5px;
    }

    // .subtitle {
    //   padding: 5px 0px;
    //   color: rgb(244, 99, 76);
    //   margin-top: 15px;
    //   font-weight: 700;
    //   font-size: 12px;
    // }
    .overDiv{
      height: 23px;
      /*line-height: 10px;*/
      /*border: 1px solid red;*/
      display: flex;
      align-items: center;
      /*justify-content: center;*/
    }
    /*实际结束更改*/
    .overDiv>div{
      font-weight: bolder;
    }
    .overDiv>span{
      font-size: 13px;
      color: rgb(110,110,110);
    }

    .calendar_icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url(../../../assets/images/calendar.png) no-repeat center center;
      background-size: cover;
      margin-left: 10px;
      background-position-y: -1px;
    }

    /*保存按钮不可点击样式*/
    .saveButton_Disable{
      /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
      background-color: rgba(184, 184, 184, 0.2);
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      line-height: 1.22rem;
      margin-left: 0;
      text-align: center;
    }
    /*保存按钮可点击样式*/
    .saveButton_Enable{
      background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      line-height: 1.22rem;
      text-align: center;
    }
    .dropDownList{
      width: 100%;
      position: absolute;
      z-index: 99;
      margin-top: 50px;
    }
    /*下拉菜单选项样式*/
    .part-inputpart-dropDownList-option{
      text-align: center;
      line-height: 35px;
      background-color: #f5f5f5;
      font-size: 12px;
      border-bottom: 1px white solid;
      color: black;
      float: left;
      width: 345px;
      height: 35px;
      /*position: fixed;*/
      /*z-index: 2001;*/
      /*margin-top: 300px;*/
      /*margin-left: 15px;*/
    }
    .divide {
      font-size: 14px;
      color: #efefef;
      margin:0 7px 0 10px;
    }
    .part-inputpart-row-header{
      font-weight: bold;
      font-size: 14px;
      width: 90px;
      color: black;
    }
    .part-inputpart-row-graytext {
      color: #d9d9d9;
      width: 100%;
      font-size: 14px;
    }
    .part-inputpart-row {
      width: 345px;
      float: left;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: white;
      height: 50px;
      border-radius: 8px;
      margin: 0 5px 15px 0;
    }
    .notdot {
      display: inline-block;
      padding: 3.5px;
      border-radius: 50%;
      background-color: rgb(136, 136, 136);
      vertical-align: middle;
      margin-right: 13px;
      margin-left: 15px;
    }
    .isdot{
      display: inline-block;
      width: 8px;
      height: 6px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 13px;
      background-color: rgb(251,91,64);
      margin-left: 15px;
    }
    .optionSelectedColor{
      color: #ff5d3b;
    }
    .part-inputpart-dropDownList-option-selected{
      color: #ff5d3b;
    }
    .part-inputpart-row-normaltext{
      color: black;
    }
    .part-inputpart-row-right-downArrow{
      width: 9px;
      height: 9px;
      margin-right: 15px;
    }
    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow{
      width: 9px;
      height: 9px;
      /*transform: rotateX(180deg);*/
      margin-right: 15px;
    }

    .panel {
      width: 345px;
      background-color: rgb(255, 255, 255);
      border-radius: @redius;
      position: relative;
      font-size: 14px;
      text-align: left;
      overflow: hidden;
      padding: 10px ;
    }

  }
</style>

<style lang="less" scoped>
/*项目选择模块*/
.module-small {
//   width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: space-between;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  padding: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式 未选中*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  /*background-color:#f7f8fa;*/
  border-radius: 0.1rem;
  font-size: 17px;
}
</style>

<style lang="less" scoped>
    .types {
        width: 94%;
        // height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #FFFFFF;
        padding: 0.27rem;
        font-size: 12px;
    }

    .typeDiv{
        display: flex;
        align-items: center;
    }

    .typef {
        // width: 60px;
        text-align: right;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 18px;
    }

    .order {
        // width: 80px;
        text-align: right;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 10px;
    }

    .sif {
        width: auto;
        height: 15px;
        font-size: 13px;
        color: #000000;
        font-weight: bold;
        // margin: 15px 0 0 21px;
    }

    .small-icon {
        width: 8px;
        height: 8px;
        margin-top: 3px;
        margin-left: 2px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .small-icon-rotate {
        width: 8px;
        height: 8px;
        margin-top: 3px;
        margin-left: 2px;
        background-image: url("../../../assets/images/small-icon.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transform: rotate(180deg);
    }

    .screen {
        width: 80%;
        height: 90%;
        padding-top: 3.5rem;

    }
    .screety {
        font-size: 13px;
        font-weight: bold;
        /*margin-left: 20px;*/
        /*margin-top: 25px;*/
      margin: 20px;
    }

    .scrsty {
      display: flex;
      align-items: center;
      justify-content: space-between;

    }

    /*垃圾桶*/
    .clean-icon {
        height: 20px;
        /*padding: 0 5px 0 23px;*/
      margin-right: 10px;
      margin-left: 10px;
        width: 16px;
    }

    /*清空*/
    .clean_button {
        /*position: absolute;*/
        /*top: 83px;*/
        font-size: 14px;
        color: rgba(255, 93, 59, 1);
    }

    /*筛选按钮*/
    .screen_button {
        /*position: absolute;*/
        /*left: 150px;*/
        /*padding: 11px 31px;*/
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
        font-size: 14px;
        font-weight: bold;
        border-radius: 6px;
        color: rgb(255, 255, 255);
        border: none;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
    }

    /*遮罩*/
    .black_overlay {
        display: none;
        position: absolute;
        top: 38%;
        left: 0%;
        width: 100%;
        height: 62%;
        background-color: black;
        z-index: 1001;
        -moz-opacity: 0.8;
        opacity: .80;
        filter: alpha(opacity=88);
    }

    /*遮罩*/
    .black_order_overlay {
        display: none;
        position: absolute;
        top: 45%;
        left: 0%;
        width: 100%;
        height: 55%;
        background-color: black;
        z-index: 1001;
        -moz-opacity: 0.8;
        opacity: .80;
        filter: alpha(opacity=88);
    }


    .sty {
        margin-left: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
    }

    .stye {
        white-space: nowrap; /*强制span不换行*/
        display: inline-block; /*将span当做块级元素对待*/
        overflow: hidden; /*超出宽度部分隐藏*/
        text-overflow: ellipsis; /*超出部分以点号代替*/
        line-height: 0.9; /*数字与之前的文字对齐*/
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 7px 12px;
      width: 24%;
        /*margin-right: 6px;*/
    }

    .styeprice {
        white-space: nowrap; /*强制span不换行*/
        display: inline-block; /*将span当做块级元素对待*/
        overflow: hidden; /*超出宽度部分隐藏*/
        text-overflow: ellipsis; /*超出部分以点号代替*/
        line-height: 0.9; /*数字与之前的文字对齐*/
        color: #999999;
        font-size: 12px;
        text-align: center;
        padding: 7px 12px;
      width: 24%;
        /*margin-right: 16px;*/
    }

    .bgcolor {
        background: rgb(255, 221, 220);
        color: rgb(255, 91, 62);
        font-size: 12px;
        text-align: center;
        border-radius: 5px;
        width: 24%;
      /*display: flex;*/
      /*align-items: center;*/
      /*justify-content: center;*/
    }
    .selectList {
        position: fixed;
        z-index: 9;
        width: 100%;
        height: 220px;
        top: 8%;
        padding: 5px 0 14px 0;
        background-color: white;
    }

    .select {
        width: 100%;
        height: 35px;
        line-height: 30px;
        font-size: 14px;
        text-align: left;
        margin-left: 15px;
        color: #999999;
    }

    .selectActive {
        color: #fe5e3a;
    }

    .reviews {
        width: 100%;
        overflow: visible;
        height: auto;
        position: absolute;
        top: 22%;
        background-color: #FFFFFF;
    }

    .content-divide-sa {
        font-size: 14px;
        /*margin: 15px 0 0 35px;*/
        color: #f4f4f4;
    }

    .content-divide-ac {
        font-size: 14px;
        /*margin: 15px 0 0 25px;*/
        color: #f4f4f4;
    }

    .content-divide-dos {
        font-size: 14px;
        margin: 15px 0 0 5px;
        color: #999999;
    }

    .keyAddress {
        font-size: 12px;
        /*height: auto;*/
        padding-top: 3px;
        /*padding-left: 17px;*/
        color: #7d7b7b;
    }

</style>

<style lang="less" scoped>
.potential{
    .subtitle {
      padding: 5px 0px;
      color: rgb(244, 99, 76);
      margin: 15px 0 0 0;
      font-weight: 700;
      font-size: 13px;
    }
    .dataDetail{   //数据
        width: 345px;
        font-size: 14px;
        padding: 10px 0;
        display: flex;
        border-radius: 0.2rem;
        background-color: #FFFFFF;
        .dataStaffName{  //左侧名字
            display: flex;
            flex-direction: column;
            >div:nth-of-type(1){
                font-weight: bolder;
            }
            >div:nth-of-type(even){
                    background-color: #fdebd5;
            }
            >div{
                padding: 0 10px;
                // font-size: 14px;
                width: 50px;
                height: 25px;
                line-height: 25px;
            }
        }
        .staffData{  //右侧数据
            width: 265px;
            // display: inline-block;
            overflow:hidden;
            white-space:nowrap;
            overflow-x: scroll;
            display: flex;
            flex-direction: column;

            .dataDetailHeaderRow{  //右侧表头横hang
                height: 25px;
                line-height: 27px;
                // .dataDetailHeader:nth-of-type(1),.dataDetailHeader:nth-of-type(27),.dataDetailHeader:nth-of-type(28){     //短一点
                //     width: 70px;
                // }
                // .dataDetailHeader:nth-of-type(3),.dataDetailHeader:nth-of-type(4){     //短一点
                //     width: 90px;
                // }
                .dataDetailHeader{     //数据的每个表头
                    display:inline-block;
                    width: 120px;
                    font-size: 0.4rem;
                    font-weight: bolder;
                }
            }
            .dataDetailRightDataRow:nth-of-type(even){
                background-color: #fdebd5;
                >.dataDetailRightData{
                    background-color: #fdebd5;
                }
            }
            .dataDetailRightDataRow{  //右侧数据横hang
                height: 25px;
                line-height: 25px;
                // .dataDetailRightData:nth-of-type(1),.dataDetailRightData:nth-of-type(27),.dataDetailRightData:nth-of-type(28){     //短一点
                //     width: 70px;
                // }
                // .dataDetailRightData:nth-of-type(3),.dataDetailRightData:nth-of-type(4){     //短一点
                //     width: 90px;
                // }
                .dataDetailRightData{     //右侧每个数据
                    display:inline-block;
                    width: 120px;
                    overflow: hidden;
                    font-size: 14px;
                    height: 25px;
                }
                .dataDetailRightDataMoney{
                    box-sizing: border-box;
                    padding: 0 50px 0 0;
                    text-align: right;
                }
            }
        }

    }   
}
.bankInfo{
  box-sizing: border-box;
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  .detailInfo{
    margin-left: 15px;
    font-size: 0.35rem;
    >span:nth-of-type(1){
      display: inline-block;
      width: 70px;
    }
  }
  .detailInfoTitle{
    margin-bottom: 5px;
    color: rgba(255, 93, 59, 1);
    font-size:0.43rem
  }
}
</style>